import React from 'react';
import styled from 'styled-components';
import {
  TextualContent,
} from 'src/infrastructure/gatsby/types/textual-content';
import RichText from '@shipae/components-sandbox/component/rich-text';
import ReactMarkdown from 'markdown-to-jsx';
import gloabalReactMdOptions from '../shared/react-makdown-options';

const Section = styled.section`
  padding: 6rem calc((100% - var(--container-width)) / 2);
`;

const TextualContentSection: React.FC<TextualContent> = ({
  sectionId,
  content,
}) => (
  <Section id={sectionId || 'textual-content'}>
    <RichText>
      <ReactMarkdown options={gloabalReactMdOptions}>
        {content || ''}
      </ReactMarkdown>
    </RichText>
  </Section>
);

export default TextualContentSection;
